import React, { useMemo } from 'react';
import { Grid, Typography, makeStyles, Theme } from '@material-ui/core';
import { Link, graphql } from 'gatsby';

const useStyles = makeStyles<Theme>(({ shape, palette }) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  item: {
    borderRadius: shape.borderRadius,
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: palette.grey['100'],
    },
  },
  thumbnail: {
    width: 64,
    height: 64,
    margin: 0,
    borderRadius: shape.borderRadius,
  },
}));

interface Props {
  thumbnail: string;
  title: string;
  path: string;
  date?: string;
  categories?: string[];
  onClick?: (path: string) => void;
}
const SimplePostItem = ({ thumbnail, title, path, date = '', categories = [], onClick }: Props) => {
  const styles = useStyles();
  const category = useMemo(() => {
    if (categories != null) {
      return categories.join('/');
    } else {
      return '';
    }
  }, [categories]);
  return (
    <Link to={path} className={styles.link}>
      <Grid container spacing={2} onClick={() => onClick?.(path)} className={styles.item}>
        <Grid item xs="auto">
          <img src={thumbnail} alt={title} title={title} className={styles.thumbnail} />
        </Grid>
        <Grid item xs={true}>
          <Typography color="textSecondary" gutterBottom variant="caption">
            {date}
          </Typography>
          <Typography variant="body2">{title}</Typography>
          <Typography color="textSecondary" variant="caption">
            {category}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};

export default SimplePostItem;

export const query = graphql`
  fragment SimplePostItemQuery on MarkdownRemark {
    fields {
      path
      lang
    }
    frontmatter {
      title
      categories
      date(formatString: "YYYY-MM-DD")
      thumbnail {
        childImageSharp {
          fixed(width: 320, height: 320, quality: 100, cropFocus: CENTER) {
            src
          }
        }
      }
    }
  }
`;
