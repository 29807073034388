import React, { ReactElement, memo } from 'react';
import { makeStyles, Theme, Grid } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  root: {
    '& > *': {
      marginBottom: spacing(2),
    },
  },
}));

interface Props {
  children: ReactElement[] | ReactElement;
}
const RowList = ({ children }: Props) => {
  const styles = useStyles();

  return (
    <Grid container className={styles.root}>
      {children}
    </Grid>
  );
};

export default memo(RowList);
