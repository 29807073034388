import React, { useCallback, useMemo } from 'react';
import SimplePostItem from 'components/Items/SimplePostItem';
import WidgetCard from 'components/Cards/Widgets';
import { makeStyles } from '@material-ui/core';
import { usePopularPosts } from 'src/hooks/usePopularPosts';

const MAX_POST_COUNT = 5;
const useStyles = makeStyles({
  wrapper: {
    margin: 0,
    padding: 0,
  },
});

interface Props {
  onClick?: (path: string) => void;
}
const PopularPostsCard = ({ onClick }: Props) => {
  const styles = useStyles();
  const posts = usePopularPosts();

  const recent = useMemo(() => {
    return posts.splice(0, MAX_POST_COUNT);
  }, [posts]);

  const handlePostClick = useCallback(
    (path: string) => {
      onClick?.(path);
    },
    [onClick]
  );

  return (
    <WidgetCard title="많이 읽는 포스팅">
      <ul className={styles.wrapper}>
        {recent.map((post, index) => (
          <SimplePostItem
            key={post.fields.path ?? index}
            thumbnail={post.frontmatter.thumbnail?.childImageSharp?.fixed?.src ?? ''}
            title={post.frontmatter.title ?? ''}
            date={post.frontmatter.date}
            path={post.fields.path ?? '/'}
            categories={post.frontmatter.categories}
            onClick={() => handlePostClick(post.fields.path)}
          />
        ))}
      </ul>
    </WidgetCard>
  );
};

export default PopularPostsCard;
