import React, { ReactNode } from 'react';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';

interface Props {
  title?: string;
  children: ReactNode;
}
const WidgetCard = ({ title = '', children }: Props) => {
  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Typography variant="caption" color="textSecondary">
            {title}
          </Typography>
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default WidgetCard;
