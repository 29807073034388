import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { ImageSharp } from 'models/image';
import PopularPostsCard from 'components/Cards/Widgets/PopularPosts';

const useStyles = makeStyles<Theme>(({ spacing, breakpoints, palette, typography }) => ({
  noResultsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '80vh',
    paddingTop: spacing(3),
    paddingBottom: spacing(3),
  },
  image: {
    width: '40%',
    marginBottom: spacing(3),
    [breakpoints.up('sm')]: {
      width: 250,
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: spacing(3),
    '& strong': {
      color: palette.primary.main,
      fontWeight: typography.fontWeightBold,
      fontSize: typography.h6.fontSize,
    },
  },
  homeButton: {
    textDecoration: 'none',
    marginTop: spacing(3),
  },
}));

interface Props {
  keyword: string;
  onClickPost?: (path: string) => void;
  onClickHome?: () => void;
}
const NoResults = ({ keyword, onClickPost, onClickHome }: Props) => {
  const styles = useStyles();
  const { noResultsImage } = useStaticQuery<{ noResultsImage: ImageSharp }>(graphql`
    query {
      noResultsImage: file(absolutePath: { regex: "/no-search-results.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box className={styles.noResultsWrapper}>
      <Image className={styles.image} fluid={noResultsImage.childImageSharp.fluid} alt="검색결과 없음" />
      <Typography className={styles.title} component="h3">
        <strong>{keyword}</strong>에 대한 검색 결과를 찾지 못했어요.
        <br />
        이런 포스팅은 어떠신가요?
      </Typography>
      <PopularPostsCard onClick={onClickPost} />
      <Link to="/" className={styles.homeButton}>
        <Button size="large" variant="contained" color="primary" onClick={onClickHome}>
          다른 포스팅 보러가기
        </Button>
      </Link>
    </Box>
  );
};

export default NoResults;
