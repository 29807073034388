import React, { useEffect, useMemo } from 'react';
import { useAllPosts } from 'hooks/useAllPosts';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Link } from 'gatsby';
import PostIntroCard from 'components/Cards/Posts';
import RowList from 'components/Lists/RowList';
import { generateLogger } from 'utils/loggers';
import { searchPost } from 'utils/searchPost';
import NoResults from './NoResults';
import { parseQueryString } from 'temen';

const logger = generateLogger('search_results_page');
const handlePostClick = (path: string) => {
  logger.click('click_posting', { path });
};
const handleMainButtonClick = () => {
  logger.click('click_home_button');
};

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  searchInput: {
    marginBottom: spacing(4),
  },
  items: {
    transition: 'transform 0.2s ease-in-out',
    '& a': {
      textDecoration: 'none',
    },
    '&:hover': {
      transform: 'scale(1.01)',
    },
  },
  noResultsWrapper: {
    height: '80vh',
  },
}));

interface Props {
  location: Location;
}
const SearchResultsPage = ({ location }: Props) => {
  const styles = useStyles();
  const allPosts = useAllPosts();
  const keyword = useMemo(() => {
    return parseQueryString(location.search).q;
  }, [location.search]);

  const posts = useMemo(() => {
    if (keyword == null) {
      return [];
    }

    return searchPost(keyword, allPosts);
  }, [allPosts, keyword]);

  const isExistResults = posts.length > 0;

  useEffect(() => {
    logger.view();
  }, []);

  useEffect(() => {
    logger.impression('show_search_result', {
      keyword,
      isExistResults,
    });
  }, [isExistResults, keyword]);

  if (keyword == null) {
    return null;
  }

  return (
    <Layout
      title={
        isExistResults && (
          <Layout.Title
            title={`"${keyword}"에 대한 검색 결과`}
            description={`${posts.length}개 포스팅을 찾았어요! 🙌`}
          />
        )
      }
    >
      <SEO title={`${keyword} 검색 결과`} />
      {isExistResults ? (
        <RowList>
          {posts.map(p => (
            <Grid item xs={12} key={p.frontmatter.title} className={styles.items}>
              <Link to={p.fields.path} onClick={() => handlePostClick(p.fields.path)}>
                <PostIntroCard
                  title={p.frontmatter.title ?? ''}
                  description={p.excerpt ?? ''}
                  thumbnail={p.frontmatter.thumbnail.childImageSharp?.fluid?.src ?? ''}
                  date={p.frontmatter.date}
                />
              </Link>
            </Grid>
          ))}
        </RowList>
      ) : (
        <NoResults keyword={keyword} onClickPost={handlePostClick} onClickHome={handleMainButtonClick} />
      )}
    </Layout>
  );
};

export default SearchResultsPage;
