import { Post } from 'models/post';
import uniqBy from 'lodash/uniqBy';

const isIncludesWithIgnoreCase = (originString: string, targetString: string) => {
  const origin = originString.toLowerCase();
  const target = targetString.toLowerCase();
  return origin.includes(target);
};

export const searchPost = (keyword: string, posts: Post[]) => {
  const titleFiltered = posts.filter(p => isIncludesWithIgnoreCase(p.frontmatter.title ?? '', keyword));
  const categoryFiltered = posts.filter(p =>
    p.frontmatter.categories?.find(category => isIncludesWithIgnoreCase(category, keyword))
  );
  const tagFiltered = posts.filter(p => p.frontmatter.tags?.find(tag => isIncludesWithIgnoreCase(tag, keyword)));

  return uniqBy([...titleFiltered, ...categoryFiltered, ...tagFiltered], post => post.frontmatter.title);
};
