import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { popularPostsAtom } from 'atoms/popularPosts';
import { Post } from 'models/post';
import { useAllPosts } from 'hooks/useAllPosts';

export const usePopularPosts = () => {
  const allPosts = useAllPosts();
  const [popularPostSlugs] = useRecoilState(popularPostsAtom);

  const popularPosts = useMemo(() => {
    return popularPostSlugs.map(slug => {
      return allPosts.find(p => p.fields.slug === slug);
    });
  }, [allPosts, popularPostSlugs]);

  return popularPosts.filter(v => v != null) as Post[];
};
