import React, { ReactNode, useMemo } from 'react';
import { Paper, Grid, Typography, Box, makeStyles, Theme } from '@material-ui/core';
import format from 'date-fns/format';

const useStyle = makeStyles<Theme>(({ spacing, breakpoints, shape }) => ({
  root: {
    padding: spacing(2.5),
  },
  infoWrapper: {
    order: 2,
    [breakpoints.up('md')]: {
      order: 1,
    },
  },
  thumbnailWrapper: {
    order: 1,
    [breakpoints.up('md')]: {
      order: 2,
    },
    '& > div': {
      position: 'relative',
      width: '100%',
      paddingBottom: '70%',
      [breakpoints.up('md')]: {
        width: 120,
        paddingBottom: '100%',
      },
    },
  },
  thubmnail: {
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    borderRadius: shape.borderRadius,
  },
  title: {
    fontWeight: 'bold',
  },
  description: {
    whiteSpace: 'pre-line',
  },
  date: {
    marginTop: spacing(1),
    fontWeight: 200,
  },
  badge: {
    marginBottom: spacing(1),
  },
}));

interface Props {
  title: string;
  thumbnail: string;
  description: string;
  date?: string;
  badge?: ReactNode;
  onClick?: () => void;
}
const PostIntroCard = ({ title, thumbnail, description, date, badge, onClick }: Props) => {
  const styles = useStyle();

  const formattedDate = useMemo(() => {
    if (date == null) {
      return undefined;
    }

    return format(new Date(date), 'yyy MMM dd');
  }, [date]);

  return (
    <Paper variant="outlined" className={styles.root} onClick={onClick}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md className={styles.infoWrapper}>
          <Box>
            {badge != null ? <Box className={styles.badge}>{badge}</Box> : null}
            <Typography gutterBottom variant="body1" component="h2" className={styles.title}>
              {title}
            </Typography>
            <Typography gutterBottom component="p" variant="caption" className={styles.description}>
              {description}
            </Typography>
            <Typography variant="caption" component="p" color="textSecondary" className={styles.date}>
              {formattedDate}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md="auto" className={styles.thumbnailWrapper}>
          <div>
            <img src={thumbnail} alt={title} className={styles.thubmnail} />
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PostIntroCard;
